<template>
    <div style="display: flex;flex-wrap: wrap;">
        <el-select v-model="query.series" clearable filterable placeholder="系列" @change="reload">
<!--            <el-option v-for="c in this.series_list" :label="c.i18n_name.ZH_HANS" :value="c.id"></el-option>-->
            <el-option v-for="series in this.series_list" :label="series" :value="series"></el-option>
        </el-select>
<!--        <el-cascader v-model="query.major_tag" placeholder="主要标签" clearable filterable :options="major_list"-->
<!--                     :props="props" @change="reload"></el-cascader>-->
<!--        <el-cascader v-model="query.minor_tag" placeholder="次要标签" clearable filterable :options="minor_list"-->
<!--                     :props="props" @change="reload"></el-cascader>-->
<!--        <el-cascader v-model="query.assistant_tag" placeholder="辅助标签" clearable filterable :options="assistant_list"-->
<!--                     :props="props" @change="reload"></el-cascader>-->
        <el-select v-model="query.status" placeholder="审核状态" clearable @change="reload" style="width: 100px">
            <el-option label="审核通过" value="AUDITED"></el-option>
            <el-option label="审核中" value="AUDITING"></el-option>
            <el-option label="审核不通过" value="REJECTED"></el-option>
        </el-select>
        <el-select v-model="query.unauthorized" placeholder="版权状态" clearable @change="reload" style="width: 100px">
            <el-option label="无版权" :value="true"></el-option>
            <el-option label="有版权" :value="false"></el-option>
        </el-select>
        <el-select v-model="query.online" placeholder="上线状态" clearable @change="reload" style="width: 100px">
            <el-option label="已上线" :value="true"></el-option>
            <el-option label="未上线" :value="false"></el-option>
        </el-select>
<!--        <el-select v-model="query.source" placeholder="来源" clearable @change="reload" style="width: 150px">-->
<!--            <el-option v-for="s in source_list" :value="s"></el-option>-->
<!--        </el-select>-->
<!--        <el-select v-model="query.module" placeholder="模块" clearable @change="reload" style="width: 100px">-->
<!--            <el-option label="图库" value="LIBRARY"></el-option>-->
<!--            <el-option label="集合" value="COLLECTION"></el-option>-->
<!--            <el-option label="每日" value="DAILY"></el-option>-->
<!--            <el-option label="运营活动" value="EVENT"></el-option>-->
<!--        </el-select>-->
        <el-input v-model="query.id" placeholder="ID" @change="reload" style="width: 220px"></el-input>
        <date-range-picker v-model="query" end-placeholder="上线时间结束" start-placeholder="上线时间开始" style="width: 200px"
                           @change="reload" clearable :disabled-date="function() {}"></date-range-picker>
        <el-button :disabled="loading" type="primary" @click="openDialog()">上传</el-button>
    </div>
    <el-table style="margin-top: 20px;" v-loading="loading" :data="data">
        <el-table-column label="ID" prop="id" width="150"></el-table-column>
        <el-table-column label="系列" prop="series" width="150">
          <template #default="scope">
            <el-tag :disabled="loading" v-if="scope.row.series" v-for="series in scope.row.series">{{ series }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" width="150">
            <template #default="scope">
                <el-select :disabled="loading" style="width: 100px" v-model="scope.row.status"
                           @change="update(scope.row)">
                    <el-option label="通过" value="AUDITED"></el-option>
                    <el-option label="审核中" value="AUDITING"></el-option>
                    <el-option label="不通过" value="REJECTED"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="图片" prop="resource" width="220">
            <template #default="scope">
                <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.origin}`" style="width: 200px"/>
            </template>
        </el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="200">
            <template #default="scope">
                <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                                @change="update(scope.row)" :clearable="false"></el-date-picker>
            </template>
        </el-table-column>
        <el-table-column label="版权状态" width="150">
          <template #default="scope">
            <el-tag v-if="scope.row.unauthorized" type="danger">无版权</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
            <template #default="scope">
                <el-button :disabled="loading" :type="getDiffButtonType(scope.row)" size="small" @click="openDiffDialog(scope.row)">不同点</el-button>
                <el-button :disabled="loading" size="small" @click="openDialog(scope.row)">编辑</el-button>
                <el-button :disabled="loading" size="small" type="danger" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="total" :page-size="this.query.limit"
                       @current-change="pageChange" :disabled="loading"></el-pagination>
        <el-select v-model="query.limit" style="width: 100px" @change="reload" :disabled="loading">
            <el-option :value="20"></el-option>
            <el-option :value="50"></el-option>
            <el-option :value="100"></el-option>
        </el-select>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" label-width="100px" :model="form">
            <el-form-item label="原始图片" prop="origin" :rules="[{required: true, message: '请选择图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'images'}" :onProgress="handleProgress"
                           :before-upload="beforeUpload">
                    <img v-if="form.origin" :src="`https://jigsaw-cdn.plutodesk.com/${form.origin}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="不同图片" prop="differences" :rules="[{required: true, message: '请选择图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleDiffSuccess"
                          accept="image/*" :data="{prefix: 'images'}" :onProgress="handleProgress"
                           :before-upload="beforeUpload">
                    <img v-if="form.differences" :src="`https://jigsaw-cdn.plutodesk.com/${form.differences}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="系列" prop="series" :rules="[{required: false, message: '请选择系列'}]">
                <el-select v-model="form.series" filterable multiple allow-create :disabled="loading">
                    <el-option v-for="s in series_list" :value="s"></el-option>
                </el-select>
            </el-form-item>
<!--            <el-form-item label="价格" prop="cost">-->
<!--                <el-select v-model="form.cost" :disabled="loading">-->
<!--                    <el-option :value="0"></el-option>-->
<!--                    <el-option :value="20"></el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
          <el-form-item label="版权" prop="unauthorized">
              <el-checkbox v-model="form.unauthorized" :disabled="loading">无版权</el-checkbox>
          </el-form-item>
            <el-form-item label="包含国家" prop="include_country">
                <el-select v-model="form.include_country" filterable multiple :disabled="loading" style="width: 100%">
                    <el-option v-for="c in $root.country_list" :value="c.code"
                               :label="c.en_name + ' (' + c.code + ')'"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="排除国家" prop="exclude_country">
                <el-select v-model="form.exclude_country" filterable multiple :disabled="loading" style="width: 100%">
                    <el-option v-for="c in $root.country_list" :value="c.code"
                               :label="c.en_name + ' (' + c.code + ')'"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" text @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_diff_opened" :close="reset" width="80%">
      <el-form v-if="editing_diff.image" ref="editing_diff" label-width="30px" :model="editing_diff">
        <el-form-item>
          <div style="display: flex; justify-content: space-between; width: 100%; margin-top: 5px;">
            <div style="width: 50%; margin-top: 5px;">
              <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${editing_diff.image.origin}`" style="width: 100%; height: auto;" @load="handleImageLoad"/>
              <div id="difference-image" style="position: relative; display: inline-block;margin-top: 5px;" @click="handleImageClick">
                <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${editing_diff.image.origin}`"  class="image-origin"  style="width: 100%;height: auto;"/>
                <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${editing_diff.image.differences}`" class="image-differences" style="width: 100%; height: auto;"/>
<!--                <div v-if="editing_diff.circles" v-for="(circle, key) in editing_diff.circles" :key="key"-->
<!--                     :style="{ position: 'absolute', top: (circle.y - circle.radius) + 'px', left: (circle.x - circle.radius) + 'px',-->
<!--                     width: 2 * circle.radius + 'px', height: 2 * circle.radius + 'px', borderRadius: '50%',-->
<!--                     border: '3px solid red', zIndex: 100}"></div>-->
                <div v-if="editing_diff.circles" v-for="(circle, key) in editing_diff.circles" :key="key">
                  <div v-if="circle.visible" :style="{ position: 'absolute', top: (circle.y - circle.radius) + 'px', left: (circle.x - circle.radius) + 'px',
                    width: 2 * circle.radius + 'px', height: 2 * circle.radius + 'px', borderRadius: '50%', border: '3px solid red', zIndex: 100}">
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 50%; margin-top: 5px; margin-left: 5px">
              <div v-for="(region, index) in editing_diff.regions" style="display: flex; margin-bottom: 20px; align-items: center;">
                <label style="width: 90px; margin-right: 5px;margin-left: 15px; align-self: flex-start;">不同点 {{index}}：</label>
                <div style="position: relative; width: 50px;" @click="handleDiffClick(index)">
                  <el-image :src="`data:image/jpeg;base64,${region.image}`" style="width: 50px; height: auto;"/>
                  <div v-if="editing_diff.circles[index].visible" style="border: 2px solid red; position: absolute; width: 100%; height: 100%; top: 0; left: 0;"></div>
                </div>
                <el-radio-group v-model="editing_diff.regions[index].difficulty" :disabled="loading" style="margin-left: 30px; align-self: flex-start;">
                  <el-radio :label="1" style="font-weight: normal">简单</el-radio>
                  <el-radio :label="2" style="font-weight: normal">中等</el-radio>
                  <el-radio :label="3" style="font-weight: normal">困难</el-radio>
                </el-radio-group>
                <!-- <label for="switch" style="margin-right: 10px;margin-left: 20px; align-self: flex-start;">开关：</label>-->
                <el-switch
                    :model-value="editing_diff.regions[index].disabled === undefined || !editing_diff.regions[index].disabled" @update:model-value="(value) => toggleEnabled(index, value)"
                    :inactive-value="false" :active-value="true" :disabled="loading" style="margin-left: 30px; align-self: flex-start;"></el-switch>
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: left; margin-top: 5px;">
            <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${editing_diff.image.differences}`" style="width: 50%; height: auto;"/>
          </div>
        </el-form-item>
<!--        <el-form-item v-for="(region, index) in editing_diff.regions" :label="`不同点 ${index}：`" style="display: flex">-->
<!--          <el-image :src="`data:image/jpeg;base64,${region.image}`" :style="{width: 45 + 'px'}"/>-->
<!--&lt;!&ndash;          <el-select v-model="editing_diff.regions[index].difficulty" filterable allow-create :disabled="loading" placeholder="请选择难度" style="margin-left: 20px; align-self: flex-start;">&ndash;&gt;-->
<!--&lt;!&ndash;            <el-option v-for="s in [1, 2, 3]" :value="s" :label="s === 1 ? '简单' : s === 2 ? '中等' : '困难'"></el-option>&ndash;&gt;-->
<!--&lt;!&ndash;          </el-select>&ndash;&gt;-->
<!--          <el-radio-group v-model="editing_diff.regions[index].difficulty" :disabled="loading" style="margin-left: 20px; align-self: flex-start;">-->
<!--            <el-radio :label="1" style="font-weight: normal">简单</el-radio>-->
<!--            <el-radio :label="2" style="font-weight: normal">中等</el-radio>-->
<!--            <el-radio :label="3" style="font-weight: normal">困难</el-radio>-->
<!--          </el-radio-group>-->
<!--          &lt;!&ndash; <label for="switch" style="margin-right: 10px;margin-left: 20px; align-self: flex-start;">开关：</label>&ndash;&gt;-->
<!--          <el-switch-->
<!--              :model-value="editing_diff.regions[index].disabled === undefined || !editing_diff.regions[index].disabled" @update:model-value="(value) => toggleEnabled(index, value)"-->
<!--              :inactive-value="false" :active-value="true" :disabled="loading" style="margin-left: 50px; align-self: flex-start;"></el-switch>-->
<!--        </el-form-item>-->
      </el-form>
      <template #footer>
        <el-button type="primary" :loading="loading" text @click="reset">取消</el-button>
        <el-button type="primary" :loading="loading" @click="submitDifferences">确定</el-button>
      </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import {update} from "../libs/utils";
import {ElMessage, ElMessageBox} from 'element-plus'

export default {
    name: "Index",
    components: {DateRangePicker},
    data() {
        return {
            loading: false, data: [], total: 0, page: 1, dialog_opened: false, dialog_diff_opened: false, editing: null, series_list: [],
            props: {expandTrigger: 'hover', label: 'name', value: 'id', emitPath: false},
            query: {
                id: null,
                online: null,
                status: null,
                series: null,
                // source: null,
                // category_id: null,
                // module: null,
                // major_tag: null,
                // minor_tag: null,
                // assistant_tag: null,
                limit: 20,
                skip: 0,
                start: null,
                end: null,
                unauthorized: null
            },
            form: {
                // resource: "",
                origin: "",
                differences: "",
                // categories: [],
                // cost: 0,
                series: [],
                // source: null,
                // major_tags: [],
                // minor_tags: [],
                // assistant_tags: [],
                // operation_tags: [],
                // module: 'LIBRARY',
                // activity: null,
                status: null,
                online_date: null,
                include_country: [],
                exclude_country: [],
                desc: null,
                unauthorized: null
            },
            editing_diff: {
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/find-difference/image`, {params: this.query}).then(res => {
                this.data = res.data.data.imageList;
                this.total = res.data.data.total;
                this.loading = false;
            });
        },
        reload() {
            if (!this.query.id || this.query.id.length === 24) {
                this.skip = 0;
                this.page = 1;
                this.init();
            }
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        pageChange(page) {
            this.query.skip = (page - 1) * this.query.limit;
            this.init();
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/find-difference/image/${row.id}`, row).then(res => {
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            if(this.$refs.form){
                this.$refs.form.resetFields();
            }
            this.loading = this.dialog_opened = this.dialog_diff_opened = false;
            this.editing = null;
            this.form.include_country = [];
            this.form.exclude_country = [];
            this.form.unauthorized = null;
            this.editing_diff = {}
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/find-difference/image/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post('/cms/v1/find-difference/image', this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.origin = res.data.name
        },
        handleDiffSuccess(res) {
            this.loading = false;
            this.form.differences = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = _ => {
                        if (image.height === 1000 && image.width === 1600 && file.size <= 1000000) {
                            resolve();
                        } else {
                            reject();
                        }
                    }
                }
            }).then(_ => {
                return file;
            }, _ => {
                ElMessage.error('图片尺寸必须是1600 * 1000，大小1M以下');
                return Promise.reject()
            })
        },
        handleDiffClick(index){
          if(this.editing_diff.circles) {
            this.editing_diff.circles[index].visible = !this.editing_diff.circles[index].visible
          }
        },
        handleImageClick(event){
          const rect = document.getElementById('difference-image').getBoundingClientRect();
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;
          if (this.editing_diff.circles) {
            for (const index in this.editing_diff.circles) {
              let circle = this.editing_diff.circles[index];
              let distance = (circle.x - x) * (circle.x - x) + (circle.y - y) * (circle.y - y)
              if (distance < circle.radius * circle.radius) {
                if(circle.visible){
                  continue
                }
                circle.visible = true;
                break;
              }
            }
          }
        },
        handleImageLoad(event){
          const img = event.target;
          this.editing_diff.imageWidth = img.naturalWidth
          this.editing_diff.imageHeight = img.naturalHeight
          this.editing_diff.displayWidth = img.width;
          this.editing_diff.displayHeight = img.height;
          if(this.editing_diff.regions && Object.keys(this.editing_diff.regions).length !== 0) {
            this.loadCircles()
          }
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/find-difference/image/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        },
        getDiffButtonType(row) {
          if (!row.reviewed) {
            return 'danger';
          } else {
            return 'primary';
          }
        },
        openDiffDialog(item) {
          this.dialog_diff_opened = true
          this.editing_diff = {
            image: item,
            regions: {},
          }
          this.loadDifferences(item)
        },
        submitDifferences() {
          this.loading = true;
          const image = this.editing_diff.image
          axios.post(`/cms/v1/find-difference/image/${this.editing_diff.image.id}/diff`, {regions: this.editing_diff.regions}).then(res => {
            this.loading = false;
            if(this.editing_diff.image && image.id === this.editing_diff.image.id) {
                this.editing_diff.image.resource = res.data.data.image.resource
                this.editing_diff.image.reviewed = true
            }
            this.reset();
          })
        },
        loadDifferences(image) {
          this.loading = true
          axios.get(`/cms/v1/find-difference/image/${image.id}/diff`).then(res => {
            this.loading = false
            if(this.editing_diff.image && image.id === this.editing_diff.image.id) {
              this.editing_diff.regions = res.data.data.diffList
              for (const index in this.editing_diff.regions) {
                let region = this.editing_diff.regions[index]
                if (!region.hasOwnProperty('difficulty')) {
                  region.difficulty = 1
                }
              }
              if (typeof this.editing_diff.imageWidth !== 'undefined' && typeof this.editing_diff.imageHeight !== 'undefined') {
                this.loadCircles()
              }
            }
          })
        },
        loadCircles(){
          this.editing_diff.circles = {}
          let ratioWidth = this.editing_diff.imageWidth/this.editing_diff.displayWidth
          let ratioHeight = this.editing_diff.imageHeight/this.editing_diff.displayHeight
          for (const index in this.editing_diff.regions) {
            let circle = {}
            let region = this.editing_diff.regions[index]
            let left = parseInt(region.x / ratioWidth)
            let top = parseInt(region.y / ratioHeight)
            let width = parseInt(region.width / ratioWidth)
            let height = parseInt(region.height / ratioHeight)
            circle.x = parseInt(left + width/2)
            circle.y = parseInt(top + height/2)
            circle.radius = Math.max(width, height)
            circle.visible = false
            this.editing_diff.circles[index] = circle
          }
        },
        toggleEnabled(index, value) {
          this.editing_diff.regions[index].disabled = !value;
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get('/cms/v1/find-difference/series').then(res => {
                this.series_list = [];
                res.data.data.seriesList.forEach(s => {
                    if (s && s.key && !this.series_list.includes(s.key)) {
                        this.series_list.push(s.key);
                    }
                });
            }),
            // axios.get(`/cms/v1/category`).then(res => {
            //     this.category_list = res.data.data.categoryList.filter(c => !['ALL', 'COLLECTION'].includes(c.key));
            //     this.category_list.forEach(c => {
            //         this.category_map[c.id] = c.i18n_name.ZH_HANS;
            //     });
            // }),
            // axios.get('/cms/v1/tag/major').then(res => {
            //     this.major_list = res.data.data.tagList;
            //     this.df(this.major_map, this.major_list);
            // }),
            // axios.get('/cms/v1/tag/minor').then(res => {
            //     this.minor_list = res.data.data.tagList;
            //     this.df(this.minor_map, this.minor_list);
            // }),
            // axios.get('/cms/v1/tag/assistant').then(res => {
            //     this.assistant_list = res.data.data.tagList;
            //     this.df(this.assistant_map, this.assistant_list);
            // }),
            // axios.get('/cms/v1/source').then(res => {
            //     this.source_list = [];
            //     res.data.data.sourceList.forEach(s => {
            //         if (s && !this.source_list.includes(s)) {
            //             this.source_list.push(s);
            //         }
            //     });
            // }),
        ]).then(_ => {
          this.init();
        })
    },
}
</script>

<style scoped>

.image-origin {
  width: auto;
  height: auto;
}

.image-differences {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: 1;
}

</style>