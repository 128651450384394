<template>
    <div style="display: flex;justify-content: flex-start">
        <div>
            <el-button type="primary" :disabled="loading" @click="openDialog()">新建推荐类型</el-button>
        </div>
        <div style="margin-left: auto;">
          <el-select style="width: 150px;" v-model="day" :disabled="loading" @change="init">
            <el-option v-for="day in 14" :label="`新人第${day-1}天`" :value="parseInt(day)-1"></el-option>
          </el-select>
          <el-select style="margin-right: 20px" v-model="country" filterable :disabled="loading" @change="init" placeholder="全球">
            <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                       :label="c.en_name + ' (' + c.code + ')'">
              {{ c.en_name + ' (' + c.code + ')' }}
            </el-option>
          </el-select>
        </div>

    </div>
    <el-card shadow="never" v-for="(item, i) in data" style="margin-top: 10px"
             :body-style="{backgroundColor: 'var(--el-fill-color)'}">
      <template #header>
        <div class="card-header">
          <div>
            <span v-if="item.i18n_name['EN']" class="title" style="color: #555555;"><strong>{{ item.i18n_name['EN'] }}</strong></span>
            <span v-else class="title" style="color: #555555;"><strong>{{ item.type }}</strong></span>
            <span v-if="item.type === 'activity'"> (ID: {{ item.id}} ; 平台：{{ item.data.platform }} ; start: {{ item.data.start}} ; end: {{ item.data.end }} )</span>
            <span v-else> (ID: {{item.id}})</span>
          </div>
          <div>
            <el-button type="success" circle icon="Top" :loading="loading" size="small"
                       :disabled="i===0" @click="moveUp(i)"></el-button>
            <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                       :disabled="i===data.length - 1" @click="moveDown(i)"></el-button>
            <el-button type="primary" size="small" :disabled="loading" @click="openDialog(item)">编辑</el-button>
            <el-button type="success" size="small" :disabled="loading"
                       @click="openImageDialog(item)" v-if="item.type === 'picks' || item.type === 'rank'">内容</el-button>
            <el-button type="danger" size="small" :disabled="loading" @click="remove(item)" style="margin-right: 10px">删除</el-button>

            <el-switch size="small" v-model="item.active" :inactive-value="false" :active-value="true"
                       @change="update(item)" :disabled="loading"></el-switch>
          </div>
        </div>
      </template>
      <div class="images" v-if="item.images && item.images.length">
        <div class="wrapper" v-for="image in item.images">
          <el-image :src="`https://jigsaw-cdn.plutodesk.com/${image.thumbnail}`" fit="cover" style="height: 140px"></el-image>
        </div>
      </div>
      <div v-if="['activity'].includes(item.type) && item.data && item.data.banner">
        <el-image :src="`https://jigsaw-cdn.plutodesk.com/${item.data.banner}`" fit="cover" style="height: 140px"></el-image>
      </div>
    </el-card>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-form :model="form" label-width="120px" ref="form">
          <el-form-item label="推荐类型" prop="type" :rules="[{required: true, message: '请输入推荐类型'}]">
            <el-select v-model="form.type" filterable allow-create :disabled="loading" @change="changeType">
              <el-option v-for="s in type_list" :value="s"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="!['activity','collection'].includes(form.type)" label="首页展示数" prop="limit" :rules="[{required: true, message: '手机一行展示2张图片，平板3张图片'}]">
            <el-input v-model="form.limit" :disabled="loading" type="number"></el-input>
          </el-form-item>
          <el-form-item v-if="!['activity','collection'].includes(form.type)" :label="`${label} 名称`" v-for="(label, lang) in i18n_labels" :prop="`i18n_name.${lang}`"
                        :rules="lang==='EN'?[{required: true, message: `请输入${label}名称`}]:null">
            <el-input v-model="form.i18n_name[lang]" :disabled="loading"></el-input>
          </el-form-item>
          <el-form-item v-if="form.type === 'activity'" label="Banner" prop="data.banner" :rules="[{required: true, message: '请上传图片'}]">
            <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                       accept="image/*" :data="{prefix: 'cover'}" :onProgress="handleProgress">
              <img v-if="form.data.banner" :src="`https://jigsaw-cdn.plutodesk.com/${form.data.banner}`" style="width: 200px">
              <el-button size="small" v-else :loading="loading">上传</el-button>
            </el-upload>
          </el-form-item>

          <el-form-item v-if="form.type === 'activity'" label="平台" prop="platform">
            <el-select v-model="form.data.platform" :disabled="loading">
              <el-option v-for="p in ['android', 'ios']" :label="p" :value="p"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="form.type === 'activity'" label="动作" prop="data.type" :rules="[{required: true, message: '请选择动作'}]">
            <el-select v-model="form.data.type" :disabled="loading" @change="changeActivityType">
              <el-option v-for="(v, k) in activity_type_enum" :label="v" :value="parseInt(k)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.type === 'activity' && form.data.type === 8" label="URL" prop="data.url" :rules="[{required: true, message: '请输入url'}]">
            <el-input v-model="form.data.url" :disabled="loading"></el-input>
          </el-form-item>
          <el-form-item v-if="form.type === 'activity' && [5,6].includes(form.data.type)" label="订阅活动" prop="data.id" :rules="[{required: true, message: '请选择订阅活动'}]">
            <el-select v-model="subscriptionValue" :disabled="loading" filterable>
              <el-option v-for="c in subscription_list" :value="c.id" :label="c.event_name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.type === 'activity' && [5,6].includes(form.data.type) && form.data.rookie" label="新手">
            <el-tag>新手优惠活动</el-tag>
          </el-form-item>
          <el-form-item v-if="['picks', 'rank'].includes(form.type)" label="包含国家" prop="include_country">
            <el-select v-model="form.include_country" filterable multiple :disabled="loading" style="width: 100%">
              <el-option v-for="c in $root.country_list" :value="c.code"
                         :label="c.en_name + ' (' + c.code + ')'"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="['picks', 'rank'].includes(form.type)" label="排除国家" prop="exclude_country">
            <el-select v-model="form.exclude_country" filterable multiple :disabled="loading" style="width: 100%">
              <el-option v-for="c in $root.country_list" :value="c.code"
                         :label="c.en_name + ' (' + c.code + ')'"></el-option>
            </el-select>
          </el-form-item>
<!--        <el-form-item label="截止日期" prop="data.end" :rules="[{required: true ,message: '请选择截止日期'}]"-->
<!--                      v-if="form.type === 2">-->
<!--          <el-date-picker v-model="form.data.end" value-format="YYYY-MM-DD" :disabled="loading"></el-date-picker>-->
<!--        </el-form-item>-->
<!--          <el-form-item v-if="form.type === 'activity' && form.data.type === 3" label="分类" prop="data.key" :rules="[{required: true, message: '请选择分类'}]">-->
<!--            <el-select v-model="form.data.key" :disabled="loading" filterable>-->
<!--              <el-option v-for="c in category_list" :value="c.key" :label="c.i18n_name.EN"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="image_dialog_opened" @close="reset" width="80%">
      <el-card v-if="this.editing" style="margin-top: 5px" shadow="never" :header="this.editing.i18n_name['EN']" v-loading="loading">
        <vue-draggable-next v-model="this.editing.images" :animation="300" handle=".handle" @change="resort(this.editing.images)">
          <template v-for="image in this.editing.images">
            <div class="handle" style="display: inline-block;position: relative">
              <el-image style="margin: 5px;height: 160px"
                        :src="`https://jigsaw-cdn.plutodesk.com/${image.thumbnail}`"></el-image>
              <el-icon @click="removeImage(image)"
                       style="position: absolute; top: 5px; right: 5px; cursor: pointer">
                <CircleClose/>
              </el-icon>
            </div>
          </template>
        </vue-draggable-next>
      </el-card>
      <div style="display: flex;flex-wrap: wrap; margin-top: 20px">
        <el-select v-model="query.category_id" clearable filterable placeholder="分类" @change="reload">
          <el-option v-for="c in category_list" :label="c.i18n_name.ZH_HANS" :value="c.id"></el-option>
        </el-select>
        <el-cascader v-model="query.major_tag" placeholder="主要标签" clearable filterable :options="major_list"
                     :props="props" @change="reload"></el-cascader>
        <el-cascader v-model="query.minor_tag" placeholder="次要标签" clearable filterable :options="minor_list"
                     :props="props" @change="reload"></el-cascader>
        <el-cascader v-model="query.assistant_tag" placeholder="辅助标签" clearable filterable :options="assistant_list"
                     :props="props" @change="reload"></el-cascader>
<!--        <el-select v-model="query.status" placeholder="审核状态" clearable @change="reload" style="width: 100px">-->
<!--          <el-option label="审核通过" value="AUDITED"></el-option>-->
<!--          <el-option label="审核中" value="AUDITING"></el-option>-->
<!--          <el-option label="审核不通过" value="REJECTED"></el-option>-->
<!--        </el-select>-->
<!--        <el-select v-model="query.unauthorized" placeholder="版权状态" clearable @change="reload" style="width: 100px">-->
<!--          <el-option label="无版权" :value="true"></el-option>-->
<!--          <el-option label="有版权" :value="false"></el-option>-->
<!--        </el-select>-->
<!--        <el-select v-model="query.online" placeholder="上线状态" clearable @change="reload" style="width: 100px">-->
<!--          <el-option label="已上线" :value="true"></el-option>-->
<!--          <el-option label="未上线" :value="false"></el-option>-->
<!--        </el-select>-->
<!--        <el-select v-model="query.source" placeholder="来源" clearable @change="reload" style="width: 150px">-->
<!--          <el-option v-for="s in source_list" :value="s"></el-option>-->
<!--        </el-select>-->
<!--        <el-select v-model="query.module" placeholder="模块" clearable @change="reload" style="width: 100px">-->
<!--          <el-option label="图库" value="LIBRARY"></el-option>-->
<!--          <el-option label="集合" value="COLLECTION"></el-option>-->
<!--          <el-option label="每日" value="DAILY"></el-option>-->
<!--        </el-select>-->
        <el-input v-model="query.id" placeholder="ID" @change="reload" style="width: 220px"></el-input>
        <date-range-picker v-model="query" end-placeholder="上线时间结束" start-placeholder="上线时间开始" style="width: 200px"
                           @change="reload" clearable :disabled-date="function() {}"></date-range-picker>
      </div>
      <el-table style="margin-top: 10px" v-loading="loading" :data="image_list" @sort-change="sortChange" :sortable="true">
        <el-table-column label="预览图" prop="prefix" width="120">
          <template #default="scope">
            <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.thumbnail}`"
                      style="width: 100px"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="ID" prop="id" width="120"></el-table-column>
        <el-table-column label="分类" prop="categories" width="100">
          <template #default="scope">
            <template v-for="c in scope.row.categories">
              <template v-for="cc in category_list">
                <el-tag size="small" v-if="cc.id===c">{{ cc.i18n_name.EN }}</el-tag>
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="激励" width="80" prop="level">
          <template #default="scope">
            {{ scope.row.cost ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="上线时间" width="150" prop="online_date" sortable></el-table-column>
        <el-table-column label="主要标签" prop="major_tags" width="100">
          <template #default="scope">
            <el-tag v-for="t in scope.row.major_tags">{{ major_map[t] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="次要标签" prop="minor_tags" width="100">
          <template #default="scope">
            <el-tag v-for="t in scope.row.minor_tags">{{ minor_map[t] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="辅助标签" prop="assistant_tags" width="100">
          <template #default="scope">
            <el-tag v-for="t in scope.row.assistant_tags">{{ assistant_map[t] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="3日价值">
          <el-table-column label="展示" prop="impression_3" width="100" sortable></el-table-column>
          <el-table-column label="进入数" prop="enter_3" width="100" sortable></el-table-column>
          <el-table-column label="进入率" prop="enter_rate_3" width="100" sortable>
            <template #default="scope">
              {{ scope.row.enter_rate_3 }}%
            </template>
          </el-table-column>
<!--          <el-table-column label="完成率" prop="finish_rate_3" width="100" sortable>-->
<!--            <template #default="scope">-->
<!--              {{ scope.row.finish_rate_3 }}%-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="分享率" prop="share_rate_3" width="100" sortable>-->
<!--            <template #default="scope">-->
<!--              {{ scope.row.share_rate_3 }}%-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table-column>

        <el-table-column label="7日价值">
          <el-table-column label="展示" prop="impression_7" width="100" sortable></el-table-column>
          <el-table-column label="进入数" prop="enter_7" width="100" sortable></el-table-column>
          <el-table-column label="进入率" prop="enter_rate_7" width="100" sortable>
            <template #default="scope">
              {{ scope.row.enter_rate_7 }}%
            </template>
          </el-table-column>
<!--          <el-table-column label="完成率" prop="finish_rate_7" width="100" sortable>-->
<!--            <template #default="scope">-->
<!--              {{ scope.row.finish_rate_7 }}%-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="分享率" prop="share_rate_7" width="100" sortable>-->
<!--            <template #default="scope">-->
<!--              {{ scope.row.share_rate_7 }}%-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table-column>

        <el-table-column label="全部价值">
          <el-table-column label="展示" prop="impression_a" width="100" sortable></el-table-column>
          <el-table-column label="进入数" prop="enter_a" width="100" sortable></el-table-column>
          <el-table-column label="进入率" prop="enter_rate_a" width="100" sortable>
            <template #default="scope">
              {{ scope.row.enter_rate_a }}%
            </template>
          </el-table-column>
<!--          <el-table-column label="完成率" prop="finish_rate_a" width="100" sortable>-->
<!--            <template #default="scope">-->
<!--              {{ scope.row.finish_rate_a }}%-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="分享率" prop="share_rate_a" width="100" sortable>-->
<!--            <template #default="scope">-->
<!--              {{ scope.row.share_rate_a }}%-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table-column>

        <el-table-column label="操作" width="100">
          <template #default="scope">
            <el-button type="primary" @click="addImage(scope.row)" :disabled="loading">添加</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="total" :page-size="this.query.limit"
                       @current-change="pageChange" :disabled="loading" :current-page.sync="page"></el-pagination>
        <el-select v-model="query.limit" style="width: 100px" @change="reload" :disabled="loading">
          <el-option :value="20"></el-option>
          <el-option :value="50"></el-option>
          <el-option :value="100"></el-option>
        </el-select>
      </div>
      <template #footer>
        <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
        <el-button type="primary" :loading="loading" @click="submitImages">确定</el-button>
      </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {VueDraggableNext} from 'vue-draggable-next'
import {initI18n_required, initI18n_required_label} from "../libs/init";
import {deepcopy, update} from "../libs/utils";
import {ElMessageBox} from "element-plus";
import DateRangePicker from "../base/DateRangePicker.vue";

export default {
    name: "LibraryRecommend",
    components: {DateRangePicker, VueDraggableNext},
    data() {
        return {
            loading: false, editing: null, data: [], type_list: ['rookie', 'daily', 'recommend', 'rank', 'activity', 'picks', 'collection', 'hot'],
            image_list: [], category_list: [], major_list: [], minor_list: [], assistant_list: [],
            category_map: {}, major_map: {}, minor_map: {}, assistant_map: {}, subscription_list: [],
            activity_type_enum: {
              5: '跳转内购页',
              6: '跳转订阅页',
              8: '跳转URL',
            },
            i18n_labels: initI18n_required_label(), country: null, day: 0,
            dialog_opened: false, image_dialog_opened: false,
            form: {
                i18n_name: initI18n_required(),
                type: '',
                active: true,
                limit: null,
                data: {},
                include_country: [],
                exclude_country: [],
            },
          props: {expandTrigger: 'hover', label: 'name', value: 'id', emitPath: false},
          total: 0, page: 1,
          query: {
              id: null,
              online: null,
              category_id: null,
              status: "AUDITED",
              source: null,
              module: 'LIBRARY',
              major_tag: null,
              minor_tag: null,
              assistant_tag: null,
              limit: 20,
              skip: 0,
              start: null,
              end: null,
              unauthorized: null,
              sort_by: null,
              sort_order: 1
            },
        }
    },
    computed: {
      subscriptionValue: {
        get() {
          return this.form.data.id
        },
        set(value) {
          this.form.data.id = value
          this.subscription_list.forEach((subscription) => {
            if (this.form.data.id === subscription.id) {
              this.form.data.start = subscription.start
              this.form.data.end = subscription.end
              this.form.data.sku = subscription.sku
              this.form.data.platform = subscription.platform
              this.form.data.rookie = subscription.newbie
            }
          })
        },
      },
    },
    methods: {
        init() {
          this.dialog_opened = false;
          this.image_dialog_opened = false;
          this.loading = true;
          axios.get(`/cms/v1/library-recommend`, {params: {country: this.country, day: this.day}}).then(res => {
            this.data = res.data.data.recommendList;
            this.loading = false;
          })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.loading = false
            this.$nextTick(_ => {
              if (item) {
                this.editing = item;
                this.form.data = deepcopy(item.data)
                update(this.form, item);
              }
            })
        },
        openImageDialog(item) {
          this.editing = item;
          this.image_dialog_opened = true;
          this.loading = false
          if(!this.editing.images) {
            this.editing.images = []
          }
          this.loadImage()
        },
        handleSuccess(res) {
          this.loading = false;
          this.form.data.banner = res.data.name
        },
        handleProgress() {
          this.loading = true;
        },
        reload() {
          if (!this.query.id || this.query.id.length === 24) {
            this.query.skip = 0
            this.page = 1;
            this.loadImage();
          }
        },
        changeType() {
          this.form.data = {}
        },
        changeActivityType(value) {
          this.form.data = {type: value, platform : null, rookie: false, start:null, end: null, banner: this.form.data.banner}
        },
        moveUp(index) {
          const row = this.data.splice(index, 1);
          this.data.splice(index - 1, 0, row[0]);
          this.resort();
        },
        moveDown(index) {
          const row = this.data.splice(index, 1);
          this.data.splice(index + 1, 0, row[0]);
          this.resort();
        },
        update(row) {
          // this.loading = true;
          axios.put(`/cms/v1/library-recommend/${row.id}`, row).then(_ => {
            // this.loading = false;
          });
        },
        resort() {
          const sort = [];
          this.data.forEach(d => {
            sort.push(d.id);
          })
          axios.post(`/cms/v1/sort/library-recommend`, {sort}).then(_ => {
          })
        },
        loadImage() {
          this.loading = true
          return axios.get(`/cms/v1/image_and_metric`, {params: this.query}).then(res => {
            this.loading = false
            this.image_list = res.data.data.imageList;
            this.total = res.data.data.total;
          }).then(_ =>
              this.image_list.forEach(d => {
                d.enter_rate_3 = d.impression_3 ? parseInt(d.enter_3 / d.impression_3 * 10000) / 100.0 : 0;
                // d.finish_rate_3 = parseInt(d.finish_3 / d.enter_3 * 10000) / 100.0 || 0;
                // d.share_rate_3 = parseInt(d.share_3 / d.finish_3 * 10000) / 100.0 || 0;
                d.enter_rate_7 = d.impression_7 ? parseInt(d.enter_7 / d.impression_7 * 10000) / 100.0 : 0;
                // d.finish_rate_7 = parseInt(d.finish_7 / d.enter_7 * 100) / 100.0 || 0;
                // d.share_rate_7 = parseInt(d.share_7 / d.finish_7 * 10000) / 100.0 || 0;
                d.enter_rate_a = d.impression_a ? parseInt(d.enter_a / d.impression_a * 10000) / 100.0 : 0;
                // d.finish_rate_a = parseInt(d.finish_a / d.enter_a * 100) / 100.0 || 0;
                // d.share_rate_a = parseInt(d.share_a / d.finish_a * 10000) / 100.0 || 0;
              })
          )
        },
        reset() {
          if(this.$refs.form) {
            this.$refs.form.resetFields();
          }
          this.image_dialog_opened = this.dialog_opened = this.loading = false;
          this.form.i18n_name = initI18n_required();
          this.form.data = {}
          this.page = 1;
          this.query = {
              limit: 20,
              skip: 0,
              status: "AUDITED",
              module: 'LIBRARY',
          };
          this.editing = null;
        },
        submit() {
          this.$refs.form.validate(valid => {
            if (valid) {
              this.loading = true;
              if (this.editing) {
                axios.put(`/cms/v1/library-recommend/${this.editing.id}`, this.form).then(res => {
                  update(this.editing, res.data.data);
                  this.reset();
                })
              } else {
                axios.post(`/cms/v1/library-recommend`, this.form).then(res => {
                  this.data.unshift(res.data.data)
                  this.reset();
                  this.init()
                })
              }
            }
          })
        },
        submitImages() {
          if (this.editing) {
            this.loading = true;
            const images = [];
            this.editing.images.forEach(d => {
              images.push(d.id);
            })
            axios.put(`/cms/v1/library-recommend/images/${this.editing.id}`, {images: images}).then(_ => {
              this.reset();
            })
          }
        },
        addImage(image) {
          if (this.editing && this.editing.images) {
            if (!this.editing.images.some(img => img.id === image.id)) {
              this.editing.images.push(image);
            }
          }
        },
        sortChange({ column, prop, order }) {
          this.query.sort_by = prop
          this.query.sort_order = (order === 'descending') ? -1 : 1;
          this.reload()
        },
        removeImage(image) {
          if (this.editing && this.editing.images) {
            const index = this.editing.images.findIndex(i=> i.id === image.id);
            if (index !== -1) {
              this.editing.images.splice(index, 1);
            }
          }
        },
        pageChange(page) {
          this.page = page
          this.query.skip = (page - 1) * this.query.limit;
          this.loadImage()
        },
        remove(row) {
          ElMessageBox.confirm('确定要删除该项吗？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确定',
            type: "warning",
          }).then(_ => {
            this.loading = true;
            axios.delete(`cms/v1/library-recommend/${row.id}`).then(_ => {
              this.data.splice(this.data.indexOf(row), 1);
              this.loading = false;
            })
          }).catch(_ => {
          })
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
    },
    mounted() {
      this.loading = true;
        axios.all([
            axios.get(`/cms/v1/category`).then(res => {
                this.category_list = res.data.data.categoryList.filter(c => !['ALL', 'COLLECTION'].includes(c.key));
                this.category_list.forEach(c => {
                    this.category_map[c.id] = c.i18n_name.ZH_HANS;
                });
            }),
            axios.get('/cms/v1/tag/major').then(res => {
                this.major_list = res.data.data.tagList;
                this.df(this.major_map, this.major_list);
            }),
            axios.get('/cms/v1/tag/minor').then(res => {
                this.minor_list = res.data.data.tagList;
                this.df(this.minor_map, this.minor_list);
            }),
            axios.get('/cms/v1/tag/assistant').then(res => {
                this.assistant_list = res.data.data.tagList;
                this.df(this.assistant_map, this.assistant_list);
            }),
            axios.get('/cms/v1/subscription-all').then(res => {
              this.subscription_list = res.data.data.subscriptionList;
            }),
            // this.loadImage(),
        ]).then(_ => {
            this.init();
        })
    },
}
</script>

<style scoped>

.card-header {
  display: flex;
  justify-content: space-between;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  line-height: 24px;
}

.el-card .images {
  display: flex;
  overflow-x: auto;
}

.el-card .images .wrapper{
  height: 150px;
  width: 160px;
  //border-radius: 10px;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  margin: 0 5px;
}
</style>