<template>
    <el-dialog v-model="dialog_opened" width="80%" :title="series?series.i18n_name.ZH_HANS:''">
        <h4>共{{ data.length }}张</h4>
        <el-table :data="data" v-loading="loading">
            <el-table-column label="ID" prop="image_id" width="150"></el-table-column>
            <el-table-column label="状态" prop="status" width="100">
                <template #default="scope">
                    <div style="color: var(--el-color-success)" v-if="scope.row.status==='AUDITED'">
                        <el-icon>
                            <CircleCheckFilled/>
                        </el-icon>
                        通过
                    </div>
                    <div style="color: var(--el-color-warning)" v-else-if="scope.row.status==='AUDITING'">
                        <el-icon>
                            <Loading/>
                        </el-icon>
                        审核中
                    </div>
                    <div style="color: var(--el-color-error)" v-if="scope.row.status==='REJECTED'">
                        <el-icon>
                            <CircleCloseFilled/>
                        </el-icon>
                        不通过
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="图片" prop="origin" width="200">
                <template #default="scope">
                    <el-image lazy :src="scope.row.origin" style="width: 180px"/>
                </template>
            </el-table-column>
<!--            <el-table-column label="来源" prop="source" width="100"></el-table-column>-->
<!--            <el-table-column label="主要标签" prop="major_tags" width="100">-->
<!--                <template #default="scope">-->
<!--                    <el-tag v-for="t in scope.row.major_tags">-->
<!--                        {{ major_map[t] }}-->
<!--                    </el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column label="次要标签" prop="minor_tags" width="100">-->
<!--                <template #default="scope">-->
<!--                    <el-tag v-for="t in scope.row.minor_tags">-->
<!--                        {{ minor_map[t] }}-->
<!--                    </el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column label="辅助标签" prop="assistant_tags" width="100">-->
<!--                <template #default="scope">-->
<!--                    <el-tag v-for="t in scope.row.assistant_tags">-->
<!--                        {{ assistant_map[t] }}-->
<!--                    </el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button type="success" circle icon="Top" :loading="loading" size="small"
                               :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                    <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                               :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                    <el-button type="danger" :disabled="loading" @click="remove(scope.row)">从系列中删除</el-button>
                    <el-input v-model="scope.row.sort" :disabled="loading" style="margin-left:10px; width: 60px"
                              @change="changeSort(scope.row)"></el-input>
                </template>
            </el-table-column>
        </el-table>
<!--        <div style="margin-top: 20px">-->
<!--            <el-button type="primary" :loading="loading" @click="createImage">向集合中添加图片</el-button>-->
<!--        </div>-->
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {ElMessageBox, ElMessage} from 'element-plus'

export default {
    name: "FindDifferenceSeries",
    data() {
        return {
            loading: false, dialog_opened: false, data: [], series: null,
        }
    },
    // inject: ['major_map', 'minor_map', 'assistant_map'],
    methods: {
        init(series) {
            this.series = series;
            this.$nextTick(_ => {
                this.dialog_opened = this.loading = true;
                axios.get(`/cms/v1/find-difference/${series.id}/image`).then(res => {
                    this.data = res.data.data.imageList;
                    this.loading = false;
                })
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要从系列中删除该图片吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/find-difference/${this.series.id}/image`, {data: {image_id: row.image_id}}).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        // createImage() {
        //     ElMessageBox.prompt('请输入图片id', '提示', {
        //         cancelButtonText: '取消',
        //         confirmButtonText: '确定',
        //         inputPattern: /[a-z0-9]{24}/,
        //         inputErrorMessage: '请输入合法id',
        //     }).then(({value}) => {
        //         this.loading = true;
        //         axios.post(`/cms/v1/find-difference/${this.series.id}/image`, {image_id: value}).then(res => {
        //             this.data.push(res.data.data);
        //             this.resort();
        //             this.loading = false;
        //         }).catch(err => {
        //             ElMessage.error(err.response.data.status.message);
        //             this.loading = false;
        //         })
        //     }).catch(_ => {})
        // },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        changeSort(row) {
          const sort = row.sort;
          const index = this.data.indexOf(row);
          if (index > -1 && sort >= 0 && sort < this.data.length) {
            this.data.splice(index, 1);
            this.data.splice(sort, 0, row);
            this.resort();
          } else {
            alert("invalid index")
          }
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
                d.sort = sort.length - 1
            })
            axios.post(`/cms/v1/sort/find-difference/series/image`, {sort}).then(_ => {
            })
        },
    },
}
</script>

<style scoped>

</style>