<template>
    <div>
        <el-select v-model="form.platform" :disabled="loading" @change="init" placeholder="全球" filterable
                   clearable style="width: 150px; margin-right: 3px">
          <el-option v-for="c in ['android', 'ios']" :value="c" :label="c"></el-option>
        </el-select>
        <el-select v-model="form.country" filterable :disabled="loading" @change="formatData">
            <el-option v-for="c in country_list" :key="c.code" :value="c.code"
                       :label="c.en_name + ' (' + c.code + ')'">
                {{ c.en_name + ' (' + c.code + ')' }}
            </el-option>
        </el-select>
        <el-date-picker v-model="form.date" value-format="YYYY-MM-DD" :disabled="loading"
                        @change="init"></el-date-picker>
    </div>
    <template v-for="d in data">
        <el-card style="margin-top: 5px" shadow="never" :header="`第${d.day}天`" v-if="d" v-loading="loading">
            <vue-draggable-next v-model="d.images" :animation="300" handle=".handle" @change="sort(d.images)">
                <template v-for="rookie in d.images">
                    <div class="handle" style="display: inline-block;position: relative">
                        <el-image style="margin: 5px;height: 160px"
                                  :src="`https://jigsaw-cdn.plutodesk.com/${rookie.thumbnail}`"></el-image>
                        <el-icon @click="loading?null:remove(rookie)"
                                 style="position: absolute; top: 5px; right: 5px; cursor: pointer; color: var(--el-color-danger)">
                            <CircleClose/>
                        </el-icon>
                    </div>
                </template>
            </vue-draggable-next>
        </el-card>
    </template>
</template>

<script>
import axios from "ts-axios-new";
import {VueDraggableNext} from 'vue-draggable-next'
import {timestampToDate} from "../libs/utils";

export default {
    name: "CountryRookie",
    components: {VueDraggableNext},
    data() {
        const now = Date.now();
        return {
            loading: false, data: [], country_list: [], rookie: [],
            form: {
                country: 'US',
                date: timestampToDate(now),
                platform: 'android'
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/cms/v1/country/rookie', {params: this.form}).then(res => {
                this.country_list = [];
                this.$root.country_list.forEach(c => {
                    let valid = false;
                    res.data.data.imageList.forEach(i => {
                        if (i.country === c.code) {
                            valid = true;
                        }
                    });
                    if (valid) {
                        this.country_list.push(c);
                    }
                });
                this.rookie = res.data.data.imageList;
                this.formatData();
                this.loading = false;
            });
        },
        formatData() {
            this.data = [];
            this.rookie.forEach(r => {
                if (r.country === this.form.country) {
                    if (!this.data[r.day]) {
                        this.data[r.day] = {day: r.day, images: []}
                    }
                    this.data[r.day].images.push(r);
                }
            });
        },
        sort(images) {
            const sort = [];
            images.forEach(i => {
                sort.push(i.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/country/rookie`, {sort}).then(res => {
                this.loading = false;
            })
        },
        remove(row) {
            this.loading = true;
            axios.delete(`cms/v1/country/rookie/${row.id}`).then(res => {
                this.init()
            });
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

.el-select {
  width: 200px;
}

</style>