import {createApp} from 'vue'
import App from './App.vue'
import Login from './Login.vue'
import axios from 'ts-axios-new'
import {createRouter, createWebHashHistory} from 'vue-router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {update} from './libs/utils'
import '../src/assets/styles/global.css'
import {routes} from './routes';

const router = createRouter({
    history: createWebHashHistory(),
    routes
});


let login: any = null;
declare var google: any;

const root_props = {
    // user info
    user: {
        user_id: '',
        avatar: '',
        name: '',
        email: '',
        access: [],
    },
    country_list: [],
    user_list: [],
};

function init() {
    const app = createApp(App);
    app.use(router);
    app.use(ElementPlus, {locale: zhCn});
    const vm = app.mount('#app');
    update(vm, root_props);
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
}

window.onload = function () {
    google.accounts.id.initialize({
        client_id: import.meta.env.VITE_OAUTH2_CLIENT_ID,
        callback: (response: any) => {
            axios.post('/cms/v1/oauth2/login', {
                platform: 'google',
                id_token: response.credential
            }).then(res => {
                update(root_props.user, res.data.data);
                if (login) {
                    login.unmount();
                }
                init();
            });
        },
        auto_select: true,
    });
    google.accounts.id.prompt((notification: any) => {
        if (notification.isDisplayMoment() && notification.isNotDisplayed()) {
            login = createApp(Login);
            login.mount('#login');
        }
    });
}