<template>
    <div>
      <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table style="margin-top: 10px" :data="data">
        <el-table-column label="活动名" prop="event_name" width="150"></el-table-column>
        <el-table-column label="SKU" prop="sku" width="200"></el-table-column>
        <el-table-column style="margin-left: 10px" label="Banner" width="250" prop="resource">
          <template #default="scope">
            <el-image v-if="scope.row.banner" lazy :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.banner}`"
                      style="width: 250px"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="平台" prop="platform" width="100"></el-table-column>
        <el-table-column label="活跃" prop="active" width="100">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                           @change="update(scope.row)" :disabled="loading"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="开始时间" prop="start" width="120"></el-table-column>
        <el-table-column label="结束时间" prop="end" width="120"></el-table-column>
        <el-table-column label="周期" prop="period" width="60">
            <template #default="scope">
                {{ period_enum[scope.row.period] }}
            </template>
        </el-table-column>
        <el-table-column label="名称" prop="name" width="150"></el-table-column>
        <el-table-column label="价格" prop="price" width="100"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button type="danger" size="small" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" label-width="100px" ref="form">
           <el-form-item label="活动名" prop="event_name" :rules="[{required: true, message: '请输入活动名称'}]">
              <el-input v-model="form.event_name" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="SKU" prop="sku" :rules="[{required: true, message: '请输入sku'}]">
              <el-input v-model="form.sku" :disabled="loading || ($route.query.debug !== 'true' && editing)"></el-input>
            </el-form-item>
            <el-form-item label="Banner" prop="banner" :rules="[{required: false, message: '请上传图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                       accept="image/*" :data="{prefix: 'sales'}" :onProgress="handleProgress">
                    <img v-if="form.banner" :src="`https://jigsaw-cdn.plutodesk.com/${form.banner}`" style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传图片</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="platform" prop="platform" :rules="[{required: true, message: '请选择platform'}]">
                <el-input v-model="form.platform" :disabled="loading || ($route.query.debug !== 'true' && editing) "></el-input>
            </el-form-item>
            <el-form-item label="周期" prop="period" :rules="[{required: true, message: '请选择周期'}]">
                <el-select v-model="form.period" :disabled="loading || ($route.query.debug !== 'true' && editing)">
                    <el-option v-for="(v, k) in period_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="名称" prop="name" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="form.name" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="价格" prop="price" :rules="[{required: true, message: '请输入价格'}]">
                <el-input v-model="form.price" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="划线价格" prop="discard">
                <el-input v-model="form.discard" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="desc" prop="desc" :rules="[{required: true, message: '请提供内购说明，商店审核需要'}]">
              <el-input v-model="form.desc" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item v-if="form.platform==='ios'" label="offer" prop="offer" :rules="[{required: false, message: '请输入offer，用于老用户优惠'}]">
              <el-input v-model="form.offer" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="tip" prop="tip">
                <el-input v-model="form.tip" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="开始时间" prop="start" :rules="[{required: true, message: '请选择开始时间'}]">
                <el-date-picker value-format="YYYY-MM-DD" :disabled="loading" v-model="form.start"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="end" :rules="[{required: true, message: '请选择结束时间'},
              { validator: (rule, value, callback) => (form.start && value && value <= form.start) ?
                callback('结束时间必须大于开始时间') : callback() }]">
                <el-date-picker value-format="YYYY-MM-DD" :disabled="loading" v-model="form.end"></el-date-picker>
            </el-form-item>
        </el-form>
        <el-card shadow="never" :body-style="{position: 'relative'}">
            <div style="display: flex;justify-content: space-between">
                <div>
                    <el-radio>{{ form.name }}</el-radio>
                </div>
                <div>
                    <b style="font-size: 20px">{{ form.price }}</b>
                    <s style="font-size: 12px;color: var(--el-text-color-secondary)"
                       v-if="form.discard">{{ form.discard }}</s>
                </div>
            </div>
            <div v-if="form.tip"
                 style="position: absolute;top: 0;left: 30%;background-color: var(--el-color-danger);color: var(--el-color-white);border-radius: 5px">
                {{ form.tip }}
            </div>
        </el-card>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from '../libs/utils';
import {ElMessageBox} from "element-plus";

export default {
    name: "Subscription",
    data() {
        return {
            loading: false, data: [], period_enum: {1: '周', 2: '月', 3: '年'}, dialog_opened: false, editing: null,
            form: {
                event_name: '',
                sku: '',
                banner: "",
                platform: 'android',
                active: false,
                start: null,
                end: null,
                period: null,
                name: '',
                price: '',
                discard: '',
                tip: '',
                offer: null,
                desc: ""
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/subscription?newbie=false` ).then(res => {
                this.data = res.data.data.subscriptionList;
                this.loading = false;
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/subscription/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
        handleSuccess(res) {
          this.loading = false;
          this.form.banner = res.data.name
        },
        handleProgress() {
          this.loading = true;
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        remove(row) {
          ElMessageBox.confirm('确定要删除该项吗？', '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确定',
            type: "warning",
          }).then(_ => {
            this.loading = true;
            axios.delete(`/cms/v1/subscription/${row.id}`).then(res => {
              this.data.splice(this.data.indexOf(row), 1);
              this.loading = false;
            })
          }).catch(_ => {})
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/subscription/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/subscription?newbie=false`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>