<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="名称" width="100" prop="i18n_name.EN"></el-table-column>
        <el-table-column label="ID" width="100" prop="id"></el-table-column>
        <el-table-column label="描述" width="200" prop="i18n_desc.EN"></el-table-column>
        <el-table-column label="封面" width="200" prop="resource">
            <template #default="scope">
                <el-image :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.resource}`" lazy
                          style="width: 180px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="价格" width="100" prop="cost"></el-table-column>
        <el-table-column label="上线时间" width="200" prop="online_date"></el-table-column>
        <el-table-column label="操作" width="300">
            <template #default="scope">
<!--                <el-button type="success" circle icon="Top" :loading="loading" size="small"-->
<!--                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>-->
<!--                <el-button type="success" circle icon="Bottom" :loading="loading" size="small"-->
<!--                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>-->
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button type="success" size="small" :disabled="loading" @click="openCollection(scope.row)">内容
                </el-button>
                <el-button type="danger" size="small" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <story-collection-image ref="collection"></story-collection-image>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item :label="`${k}描述`" v-for="(_, k) in form.i18n_desc" :prop="`i18n_desc.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}描述`}]:null">
                <el-input v-model="form.i18n_desc[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="横向" prop="resource" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false"
                           :onSuccess="handleUploadSuccess('resource')"
                           accept="image/*" :data="{prefix: 'thumbnail'}" :onProgress="handleProgress">
                    <img v-if="form.resource" :src="`https://jigsaw-cdn.plutodesk.com/${form.resource}`"
                         style="width: 200px">
                    <el-button size="small" v-else :disabled="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="纵向" prop="cover" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false"
                           :onSuccess="handleUploadSuccess('cover')"
                           accept="image/*" :data="{prefix: 'thumbnail'}" :onProgress="handleProgress">
                    <img v-if="form.cover" :src="`https://jigsaw-cdn.plutodesk.com/${form.cover}`"
                         style="width: 200px">
                    <el-button size="small" v-else :disabled="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="上线日期" prop="online_date" :rules="[{required: true, message: '请选择上线时间'}]">
                <el-date-picker v-model="form.online_date" :disabled="loading" :clearable="false"
                                value-format="YYYY-MM-DD"></el-date-picker>
            </el-form-item>
            <el-form-item label="主题色" prop="color" :rules="[{required: true, validator}]">
                <el-color-picker v-model="form.color" :disabled="loading"></el-color-picker>
                <el-input v-model="form.color" :disabled="loading"
                          style="width: 100px;display: inline-block;margin-left: 20px"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import StoryCollectionImage from "./StoryCollectionImage";
import {update} from "../libs/utils";
import {ElMessageBox} from 'element-plus'
import {initI18n} from "../libs/init";

export default {
    name: "StoryCollection",
    components: {StoryCollectionImage},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null,
            major_list: [], minor_list: [], assistant_list: [],
            major_map: {}, minor_map: {}, assistant_map: {},
            form: {
                i18n_name: initI18n(),
                i18n_desc: initI18n(),
                resource: '',
                cover: '',
                color: null,
                online_date: null,
            },
        }
    },
    provide() {
        return {
            major_map: this.major_map, minor_map: this.minor_map, assistant_map: this.assistant_map,
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/story-collection`).then(res => {
                this.data = res.data.data.collectionList;
                this.loading = false;
            })
        },
        openCollection(collection) {
            this.$refs.collection.init(collection);
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.i18n_name = initI18n();
            this.form.i18n_desc = initI18n();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        handleUploadSuccess(name) {
            return res => {
                this.form[name] = res.data.name;
                this.loading = false;
            }
        },
        handleProgress() {
            this.loading = true;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/story-collection/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/story-collection`, this.form).then(res => {
                            this.data.unshift(res.data.data)
                            this.reset();
                        })
                    }
                }
            })
        },
        validator(rule, value, callback) {
            if (/^#[A-Z0-9]{6}$/.test(value)) {
                callback();
            } else {
                callback('请选择或输入正确格式的颜色码');
            }
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            axios.post(`/cms/v1/sort/story-collection`, {sort}).then(_ => {
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: "warning",
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/story-collection/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
    },
    mounted() {
        axios.all([
            axios.get('/cms/v1/tag/major').then(res => {
                this.major_list = res.data.data.tagList;
                this.df(this.major_map, this.major_list);
            }),
            axios.get('/cms/v1/tag/minor').then(res => {
                this.minor_list = res.data.data.tagList;
                this.df(this.minor_map, this.minor_list);
            }),
            axios.get('/cms/v1/tag/assistant').then(res => {
                this.assistant_list = res.data.data.tagList;
                this.df(this.assistant_map, this.assistant_list);
            }),
        ]).then(_ => {
            this.init();
        })
    },
}
</script>

<style scoped>

</style>