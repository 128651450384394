export function initI18n() {
    return {
        EN: '',
        FR: '',
        HI: '',
        IT: '',
        AR: '',
        ES: '',
        PT: '',
        DE: '',
        EL: '',
        ZH_HANS: '',
        ZH_HANT: '',
        ID: '',
        KO: '',
        TH: '',
        NL: '',
        TR: '',
        PL: '',
        VI: '',
        FI: '',
        RU: '',
        HU: '',
        JA: '',
        NO: '',
        SV: '',
        UK: '',
        MS: '',
        IW: '',
        RO: '',
        TL: '',
    }
}

export function initI18n_required() {
    return {
        EN: '',
        FR: '',
        IT: '',
        ES: '',
        PT: '',
        DE: '',
        ZH_HANS: '',
        ZH_HANT: '',
        KO: '',
        NL: '',
        PL: '',
        RU: '',
        JA: '',
        NO: '',
        MS: '',
        AR: '',
    }
}
export function initI18n_required_label() {
    return {
        EN: '英语',
        FR: '法语',
        IT: '意大利语',
        ES: '西班牙语',
        PT: '葡萄牙语',
        DE: '德语',
        ZH_HANS: '简体中文',
        ZH_HANT: '繁体中文',
        KO: '韩语',
        NL: '荷兰语',
        PL: '波兰语',
        RU: '俄语',
        JA: '日语',
        NO: '挪威语',
        MS: '马来语',
        AR: '阿拉伯语',
    }
}