<template>
    <el-calendar v-loading="loading" ref="calendar">
        <template #date-cell="{data}">
            <div>{{ data.day.split('-').slice(1).join('-') }}</div>
            <div style="display: flex;flex-wrap: wrap">
                <img style="margin: 3px;width: 30px" :src="`https://jigsaw-cdn.plutodesk.com/${i.thumbnail}`"
                     v-for="i in data_map[data.day].slice(0, 10)" v-if="data_map[data.day]">
            </div>
        </template>
    </el-calendar>
    <date-range-picker v-model="form" :disabled="loading" style="margin-top: 20px;" clearable></date-range-picker>
    <el-table v-loading="loading" :data="dataPage">
        <el-table-column label="ID" prop="id" width="130"></el-table-column>
        <el-table-column label="状态" prop="status" width="100">
            <template #default="scope">
                <el-select :disabled="loading" style="width: 80px" v-model="scope.row.status"
                           @change="update(scope.row)">
                    <el-option label="通过" value="AUDITED"></el-option>
                    <el-option label="审核中" value="AUDITING"></el-option>
                    <el-option label="不通过" value="REJECTED"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="图片" prop="resource" width="200">
            <template #default="scope">
                <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.thumbnail}`" style="width: 180px"/>
                <div style="text-align: center;">
                    <a :href="`https://jigsaw-cdn.plutodesk.com/${scope.row.resource}`" target="_blank">高清大图</a>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="来源" prop="source" width="100"></el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="140">
            <template #default="scope">
                <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                                @change="update(scope.row)" :clearable="false"></el-date-picker>
            </template>
        </el-table-column>
        <el-table-column label="描述" prop="desc">
            <template #default="scope">
                <el-input v-model="scope.row.desc" @change="update(scope.row)" :disabled="loading"></el-input>
            </template>
        </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="dataFilter.length" :page-size="size"
                       v-model:current-page="page" :disabled="loading"></el-pagination>
    </div>
</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";

export default {
    name: "Daily",
    components: {DateRangePicker},
    data() {
        return {
            loading: false, data: [], data_map: {}, dialog_opened: false, page: 1, size: 20,
            category_list: [], major_list: [], minor_list: [], assistant_list: [],
            category_map: {}, major_map: {}, minor_map: {}, assistant_map: {},
            form: {
                start: null,
                end: null,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/daily`).then(res => {
                this.data = res.data.data.imageList;
                this.reload();
                this.loading = false;
            })
        },
        reload() {
            this.data_map = {};
            this.data.forEach(image => {
                if (image.online_date && image.status === 'AUDITED') {
                    if (this.data_map[image.online_date]) {
                        this.data_map[image.online_date].push(image);
                    } else {
                        this.data_map[image.online_date] = [image];
                    }
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.post(`/cms/v1/daily`, {date: row.online_date, image_id: row.id, desc: row.desc}).then(res => {
                this.reload();
                this.loading = false;
            })
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get(`/cms/v1/category`).then(res => {
                this.category_list = res.data.data.categoryList;
                this.category_list.forEach(c => {
                    this.category_map[c.id] = c.i18n_name.ZH_HANS;
                })
            }),
            axios.get('/cms/v1/tag/major').then(res => {
                this.major_list = res.data.data.tagList;
                this.df(this.major_map, this.major_list);
            }),
            axios.get('/cms/v1/tag/minor').then(res => {
                this.minor_list = res.data.data.tagList;
                this.df(this.minor_map, this.minor_list);
            }),
            axios.get('/cms/v1/tag/assistant').then(res => {
                this.assistant_list = res.data.data.tagList;
                this.df(this.assistant_map, this.assistant_list);
            }),
        ]).then(_ => {
            this.init();
        })
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                return !this.form.start && !this.form.end || this.form.start <= d.online_date && this.form.end >= d.online_date;
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size);
        },
    }
}
</script>

<style scoped>
.el-calendar ::v-deep(.el-calendar-day) {
    height: 100px;
}
</style>