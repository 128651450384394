import FileSaver from 'file-saver'

declare const XLSX: any;

export function deepcopy(a: any) {
    let b: any = null;
    if (a && typeof a === 'object') {
        if (Array.isArray(a)) {
            b = [];
            a.forEach(aa => {
                b.push(deepcopy(aa));
            })
        } else {
            b = {};
            for (let key in a) {
                if (a.hasOwnProperty(key))
                    b[key] = deepcopy(a[key]);
            }
        }
    } else {
        b = a;
    }
    return b;
}

export function update(a: any, b: any) {
    if (a && b && typeof a === 'object' && typeof b === 'object') {
        if (Array.isArray(a) && Array.isArray(b)) {
            a.splice(0, a.length);
            b.forEach(bb => {
                a.push(deepcopy(bb));
            })
        } else if (a.length === undefined && b.length === undefined) {
            for (let key in b) {
                if (a[key] !== undefined) {
                    if (typeof a[key] === 'object' && typeof b[key] === 'object') {
                        update(a[key], b[key])
                    } else if (typeof a[key] !== 'object' && typeof b[key] !== 'object') {
                        a[key] = b[key];
                    } else if (typeof a[key] !== 'object' && b[key] === null) {
                        a[key] = b[key];
                    } else if (typeof b[key] !== 'object' && a[key] === null) {
                        a[key] = b[key];
                    }
                }
            }
        }
    }
}

export function tableToBlob(ele: HTMLElement) {
    const fixed = ele.getElementsByClassName('el-table__fixed');
    const wb = XLSX.utils.table_to_book(fixed.length ? fixed[0] : ele);
    // const wb = XLSX.utils.table_to_book(fixed.length ? fixed[0] : ele, {raw: true});
    const wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'array'});
    return new Blob([wbout], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
}

export function copyTable(ele: HTMLElement) {
    const fixed = ele.getElementsByClassName('el-table__fixed');
    const ws = XLSX.utils.table_to_sheet(fixed.length ? fixed[0] : ele);
    const html = XLSX.utils.sheet_to_html(ws);
    const item = new window.ClipboardItem({
        "text/html": new Blob(
            [html.replace(/<head>.*<\/head>/, '')],
            {type: "text/html"}
        ),
    });
    navigator.clipboard.write([item]);
}

export function timestampToDate(timestamp: number) {
    if (timestamp / 1000000000000 < 1) {
        timestamp *= 1000;
    }
    const d = new Date(timestamp);
    const year = d.getFullYear() + '';
    const month = (d.getMonth() + 1) + '';
    const day = d.getDate() + '';
    return year + '-' + (month.length < 2 ? '0' : '') + month + '-' + (day.length < 2 ? '0' : '') + day;
}

export function timestampToDatetime(timestamp: number) {
    const date = timestampToDate(timestamp);
    if (timestamp / 1000000000000 < 1) {
        timestamp *= 1000;
    }
    const d = new Date(timestamp);
    const hour = d.getHours() + '';
    const minute = d.getMinutes() + '';
    const second = d.getSeconds() + '';
    return date + ' ' + (hour.length < 2 ? '0' : '') + hour + ':' + (minute.length < 2 ? '0' : '') + minute + ':' + (second.length < 2 ? '0' : '') + second;
}

export function getQuery(name: string) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    const r = window.location.search.substr(1).match(reg);
    if (r) {
        return unescape(r[2]);
    }
    return null;
}

export function openInNewTab(url: string) {
    window.open(url);
}

export function versionValidator(rule: any[], value: string, callback: any) {
    if (!value) {
        callback()
    } else {
        let valid = true;
        const s = value.split('.');
        if (s.length > 5) {
            valid = false;
        } else {
            s.forEach(ss => {
                if (!/^\d+$/.test(ss) || ss.length > 3) {
                    valid = false;
                }
            })
        }
        if (valid) {
            callback()
        } else {
            callback('版本号不符合规范！')
        }
    }
}

export function abTestTagValidator(rule: any[], value: string, callback: any) {
    let valid = true;
    if (value.length / 4 !== parseInt(value.length / 4 + '')) {
        valid = false;
    } else {
        for (let i = 0; i < value.length / 4; i++) {
            if (!value[i * 4].match(/[A-Z]/) || !value[i * 4 + 1].match(/[a-z]/) || !value[i * 4 + 2].match(/[0-9]/) || value[i * 4 + 3] !== '|') {
                valid = false;
            }
        }
    }
    if (valid) {
        callback();
    } else {
        callback('AB测Tag不符合规范！');
    }
}

export function generateUuid() {
    const string: string = '0123456789ABCDEF';
    let ret = '';
    for (let i = 0; i < 32; i++) {
        ret += string[Math.floor(Math.random() * 16)];
    }
    return ret;
}

export function formatInt(value: number) {
    if (!value)
        return value;
    if (value > 1000) {
        const string = parseInt(value + '') + '';
        const dot = parseInt((string.length - (value < 0 ? 2 : 1)) / 3 + '');
        const head = string.length - dot * 3;
        let result = string.slice(0, head);
        for (let i = 0; i < dot; i++) {
            result += ',' + string.slice(head + i * 3, head + i * 3 + 3);
        }
        return result
    } else
        return parseFloat(value.toFixed(2));
}

export function generateDateRange(start: string, end: string) {
    const result = [];
    const date_start = new Date(start);
    const date_end = new Date(end);
    for (let i = 0; i < (date_end.getTime() - date_start.getTime()) / 1000 / 24 / 3600; i++) {
        result.push(timestampToDate(date_start.getTime() + i * 24 * 3600 * 1000));
    }
    result.push(timestampToDate(date_end.getTime()));
    return result;
}

export function formatBytes(bytes: number) {
    if (bytes < 1024) {
        return bytes.toFixed(1) + 'B';
    } else if (bytes < 1024 ** 2) {
        return (bytes / 1024).toFixed(1) + 'KB';
    } else if (bytes < 1024 ** 3) {
        return (bytes / 1024 ** 2).toFixed(1) + 'MB';
    } else if (bytes < 1024 ** 4) {
        return (bytes / 1024 ** 3).toFixed(1) + 'GB';
    } else {
        return (bytes / 1024 ** 4).toFixed(1) + 'TB';
    }
}

export function dataURLToBlob(dataURL: string) {
    let BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        let parts = dataURL.split(',');
        let contentType = parts[0].split(':')[1];
        let raw = decodeURIComponent(parts[1]);
        return new Blob([raw], {type: contentType});
    }
    let parts = dataURL.split(BASE64_MARKER);
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;
    let uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
}

export function convertAppVersion(version: string) {
    let nums = [];
    for (let i = 0; i < version.length; i += 3) {
        nums.push(parseInt(version.slice(i, i + 3)));
    }
    return nums.join('.').replace(".0.0", '');
}


export function emailValidator(rule: any[], value: string, callback: any) {
    // 校验邮箱地址 xxx@yy.zz
    if (!value || value.length === 0) {
        return callback(new Error('邮箱不能为空'));
    }
    const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    setTimeout(() => {
        if (mailReg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的邮箱格式'));
        }
    }, 100);
}

export function downloadTable(el, name, callback) {
    const blob = tableToBlob(el);
    FileSaver.saveAs(blob, name + ".xlsx");
    if (callback)
        callback();
}

export function formatDuration(duration: number) {
    let result = [];
    const hour = parseInt(duration / 1000 / 3600 + '');
    const minute = parseInt(duration / 1000 / 60 + '') - hour * 60;
    const second = parseInt(duration / 1000 + '') - hour * 60 * 60 - minute * 60;
    result.push((hour < 10 ? '0' : '') + hour);
    result.push((minute < 10 ? '0' : '') + minute);
    result.push((second < 10 ? '0' : '') + second)
    return result.join(":");
}
