<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="图片" width="100" prop="url">
            <template #default="scope">
                <el-image :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.resource}`" style="width: 90px"
                          lazy></el-image>
            </template>
        </el-table-column>
        <el-table-column label="活跃" width="100" prop="active">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="起始时间" width="160" prop="start"></el-table-column>
        <el-table-column label="截止时间" width="160" prop="end"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button type="success" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button type="error" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="封面" prop="resource" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'magic'}" :onProgress="handleProgress">
                    <img v-if="form.resource" :src="`https://jigsaw-cdn.plutodesk.com/${form.resource}`"
                         style="width: 200px">
                    <el-button size="small" v-else :disabled="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="起始时间" prop="start" :rules="[{required: true, message: '请选择起始时间'}]">
                <el-date-picker v-model="form.start" value-format="YYYY-MM-DD" :disabled="loading"></el-date-picker>
            </el-form-item>
            <el-form-item label="截止时间" prop="start" :rules="[{required: true, message: '请选择截止时间'}]">
                <el-date-picker v-model="form.end" value-format="YYYY-MM-DD" :disabled="loading"></el-date-picker>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../libs/utils";
import {ElMessageBox} from 'element-plus'

export default {
    name: "MagicCover",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false,
            form: {
                resource: '',
                active: false,
                start: '',
                end: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/magic`).then(res => {
                this.data = res.data.data.magicList;
                this.loading = false;
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/magic/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset()
                        })
                    } else {
                        axios.post(`/cms/v1/magic`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/magic/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/magic/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        handleSuccess(res) {
            this.form.resource = res.data.name;
            this.loading = false;
        },
        handleProgress() {
            this.loading = true;
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>