import Index from './components/Index.vue';
import Category from "./components/Category.vue";
import Collection from './components/Collection.vue';
import MagicCollection from './components/MagicCollection.vue';
import StoryCollection from './components/StoryCollection.vue';
import Update from './components/Update.vue';
import Daily from './components/Daily.vue';
import Carousel from "./components/Carousel.vue";
import Suspend from './components/Suspend.vue';
import Popup from './components/Popup.vue';
import MagicCover from "./components/MagicCover.vue";
import Avatar from './components/Avatar.vue';
import Rookie from './components/Rookie.vue';
import Score from './components/Score.vue';
import Metric from './components/Metric.vue';
import CountryHot from './components/CountryHot.vue';
import CountryRookie from './components/CountryRookie.vue';
import CountryManual from './components/CountryManual.vue';
import IAP from './components/IAP.vue';
import Subscription from './components/Subscription.vue';
import FindDifference from "./components/FindDifference.vue";
import FindDifferenceImage from "./components/FindDifferenceImage.vue";
import CollectionSort from "./components/CollectionSort.vue";
import NewbieSales from "./components/NewbieSales.vue";
import Match from "./components/Match.vue";
import LibraryRecommend from "./components/LibraryRecommend.vue";


export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    }, {
        name: 'Category',
        path: '/category',
        component: Category,
    }, {
        name: 'Collection',
        path: '/collection',
        component: Collection,
    }, {
        name: 'MagicCollection',
        path: '/magic-collection',
        component: MagicCollection,
    }, {
        name: 'StoryCollection',
        path: '/story-collection',
        component: StoryCollection,
    }, {
        name: 'Update',
        path: '/update',
        component: Update,
    }, {
        name: 'Daily',
        path: '/daily',
        component: Daily,
    }, {
        name: 'Carousel',
        path: '/carousel',
        component: Carousel,
    }, {
        name: 'Popup',
        path: '/popup',
        component: Popup
    }, {
        name: 'Suspend',
        path: '/suspend',
        component: Suspend,
    }, {
        name: 'MagicCover',
        path: '/magic',
        component: MagicCover,
    }, {
        name: 'Avatar',
        path: '/avatar',
        component: Avatar,
    }, {
        name: 'Score',
        path: '/score',
        component: Score,
    }, {
        name: 'Metric',
        path: '/metric',
        component: Metric,
    }, {
        name: 'Rookie',
        path: '/rookie',
        component: Rookie,
    }, {
        name: 'CountryHot',
        path: '/country/hot',
        component: CountryHot,
    }, {
        name: 'CountryRookie',
        path: '/country/rookie',
        component: CountryRookie,
    }, {
        name: 'CountryManual',
        path: '/country/manual',
        component: CountryManual,
    }, {
        name: 'IAP',
        path: '/iap',
        component: IAP,
    }, {
        name: 'Subscription',
        path: '/subscription',
        component: Subscription,
    }, {
        name: 'NewbieSales',
        path: '/newbie-sales',
        component: NewbieSales,
    }, {
        name: 'FindDifference',
        path: '/find-difference',
        component: FindDifference,
    }, {
        name: 'FindDifferenceImage',
        path: '/find-difference-image',
        component: FindDifferenceImage,
    }, {
        name: 'CollectionSort',
        path: '/collection-sort',
        component: CollectionSort,
    }, {
        name: 'Match',
        path: '/match',
        component: Match,
    }, {
        name: 'LibraryRecommend',
        path: '/library-recommend',
        component: LibraryRecommend,
    },
]