<template>
    <div style="display: inline-flex">
        <el-select v-model="platform" style="width: 100px" @change="init" filterable :disabled="loading">
          <el-option v-for="c in ['android', 'ios']" :value="c" :label="c"/>
        </el-select>
        <date-range-picker v-model="form" :disabled="loading" start-placeholder="上线开始时间"
                           end-placeholder="上线结束时间" @change="init"></date-range-picker>
        <el-select v-model="form.country" style="width: 200px" @change="init" filterable
                   :disabled="loading" placeholder="全部国家" clearable>
            <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                       :label="c.en_name + ' (' + c.code + ')'">
                {{ c.en_name + ' (' + c.code + ')' }}
            </el-option>
        </el-select>
        <el-select style="width: 200px" placeholder="分类" filterable v-model="form.category_id"
                   :disabled="loading" clearable>
            <el-option v-for="c in category_list" :value="c.id" :label="c.i18n_name.ZH_HANS"></el-option>
        </el-select>
        <el-select style="width: 200px" placeholder="来源" filterable v-model="form.source"
                   :disabled="loading" clearable>
            <el-option v-for="c in source_list" :value="c"></el-option>
        </el-select>
        <el-input style="width: 200px" placeholder="ID" v-model="form.id"
                  :disabled="loading"></el-input>
    </div>
    <downloadable-table title="素材评分" :data="dataPage" v-loading="loading" style="margin-top: 10px"
                        @sort-change="sortChange" :complete="dataFilter">
        <el-table-column label="ID" width="150" prop="image_id"></el-table-column>
        <el-table-column label="预览图" width="120">
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.thumbnail}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="分类" width="80" prop="category" sortable>
            <template #default="scope">
                <template v-for="c in scope.row.categories">
                    <template v-for="cate in category_list">
                        <el-tag v-if="c === cate.id">{{ cate.i18n_name.ZH_HANS }}</el-tag>
                    </template>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="模块" width="100" prop="module"></el-table-column>
        <el-table-column label="平台" width="100" prop="platform"></el-table-column>
        <el-table-column label="激励" width="80" prop="level">
            <template #default="scope">
                {{ scope.row.cost ? '是' : '否' }}
            </template>
        </el-table-column>
        <el-table-column label="上线时间" width="140" prop="create_date" sortable></el-table-column>
        <el-table-column label="3日价值">
            <el-table-column label="展示" prop="impression_3" width="100" sortable></el-table-column>
            <el-table-column label="进入数" prop="enter_3" width="100" sortable></el-table-column>
            <el-table-column label="进入率" prop="enter_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.enter_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="完成率" prop="finish_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.finish_rate_3 }}%
                </template>
            </el-table-column>
            <el-table-column label="分享率" prop="share_rate_3" width="100" sortable>
                <template #default="scope">
                    {{ scope.row.share_rate_3 }}%
                </template>
            </el-table-column>
        </el-table-column>
    </downloadable-table>
    <div style="margin-top: 20px;text-align: center">
        <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                       :total="dataFilter.length"></el-pagination>
    </div>
</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import DownloadableTable from "../base/DownloadableTable";

export default {
    name: "Score",
    components: {DownloadableTable, DateRangePicker},
    data() {
        return {
            loading: false, category_list: [], data: [], page: 1, size: 20, source_list: [], platform: 'android',
            form: {
                start: null,
                end: null,
                country: null,
                category_id: null,
                enter: 1,
                finish: 2,
                download: 4,
                share: 5,
                id: null,
                key: null,
                author: null,
                source: null,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/metric`, {
                    params: {
                        country: this.form.country,
                        start: this.form.start,
                        end: this.form.end,
                        platform: this.platform,
                    }
                }).then(res => {
                    this.data = res.data.data.metricList;
                }), axios.get('/cms/v1/source').then(res => {
                    this.source_list = [];
                    res.data.data.sourceList.forEach(s => {
                        if (s && !this.source_list.includes(s)) {
                            this.source_list.push(s);
                        }
                    });
                }),
            ]).then(_ => {
                this.data.forEach(d => {
                    d.enter_rate_3 = d.impression_3 ? parseInt(d.enter_3 / d.impression_3 * 10000) / 100.0 : null;
                    d.finish_rate_3 = parseInt(d.finish_3 / d.enter_3 * 10000) / 100.0 || 0;
                    d.bomb_3 = parseInt(d.bomb_3 / d.enter_3 * 100) / 100.0 || 0;
                    d.bucket_3 = parseInt(d.bucket_3 / d.enter_3 * 100) / 100.0 || 0;
                    d.magic_3 = parseInt(d.magic_3 / d.enter_3 * 100) / 100.0 || 0;
                    d.download_rate_3 = parseInt(d.download_3 / d.finish_3 * 10000) / 100.0 || 0;
                    d.share_rate_3 = parseInt(d.share_3 / d.finish_3 * 10000) / 100.0 || 0;
                    d.enter_rate_7 = d.impression_7 ? parseInt(d.enter_7 / d.impression_7 * 10000) / 100.0 : null;
                    d.finish_rate_7 = parseInt(d.finish_7 / d.enter_7 * 100) / 100.0 || 0;
                    d.share_rate_7 = parseInt(d.share_7 / d.finish_7 * 10000) / 100.0 || 0;
                });
                this.sortChange({});
                this.loading = false;
            })
        },
        resort() {
            this.data.forEach(d => {
                d.score = this.form.enter * d.enter_7 + this.form.finish * d.finish_7 / d.enter_7 + this.form.download * d.download_7 / d.finish_7 + this.form.share * d.share_7 / d.finish_7;
            });
            this.data.sort(function (a, b) {
                return b.score - a.score;
            })
        },
        sortChange({column, prop, order}) {
            if (prop) {
                this.data.sort(function (a, b) {
                    return order === 'descending' ? (b[prop] < a[prop] ? -1 : 1) : (a[prop] < b[prop] ? -1 : 1);
                })
            } else {
                this.data.sort(function (a, b) {
                    return b.enter_rate_3 - a.enter_rate_3;
                })
            }
        },
    },
    mounted() {
        this.loading = true;
        axios.get('/cms/v1/category').then(res => {
            this.category_list = res.data.data.categoryList;
            this.init();
        })
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                let valid = d.impression_3 > 100;
                valid &= !this.form.id || d.image_id.includes(this.form.id);
                valid &= !this.form.key || d.key && d.key.includes(this.form.key);
                valid &= !this.form.category_id || d.categories && d.categories.includes(this.form.category_id);
                valid &= !this.form.source || d.source === this.form.source;
                return valid;
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size)
        }
    }
}
</script>

<style scoped>

</style>