<template>
    <el-table :data="data" v-loading="loading">
        <el-table-column label="内容" prop="style" width="200">
            <template #default="scope">
                {{ iap_style_enum[scope.row.style] }}
            </template>
        </el-table-column>
        <el-table-column label="活跃" prop="active" width="200">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import axios from "ts-axios-new";

export default {
    name: "IAP",
    data() {
        return {
            loading: false, data: [], iap_style_enum: {1: '图片', 2: '订阅', 3: '金币', 4: '道具', 5: '去广告', 6: '新手礼包'},
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/iap`).then(res => {
                this.data = res.data.data.iapList;
                this.loading = false;
            });
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/iap/${row.id}`, row).then(_ => {
                this.loading = false;
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            axios.post(`/cms/v1/sort/iap`, {sort}).then(_ => {
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>